<template>
  <section>
    <div class="mt-2 mb-4 d-block d-md-none">
      <div class="paragraph-1 mb-2">
        <p
          class="custom-paragraph custom-paragraph-xl text-black text-center mb-1"
        >
          <b>Pusat Bimbingan Belajar</b>
        </p>
        <p class="custom-paragraph custom-paragraph-xl text-black text-center">
          <b>SD, SMP, SMA / SMK</b>
        </p>
      </div>

      <div class="paragraph-2 mb-2">
        <p class="custom-paragraph-xxl text-center">
          <strong class="text-primary">EINSTEIN LEARNING PLUS</strong>
        </p>
      </div>
      <div class="paragraph-3" style="margin-bottom: 5px">
        <p
          class="custom-paragraph custom-paragraph-xl text-black text-center mb-1"
        >
          <b>Persiapan UH, PTS, PAS, US</b>
        </p>
        <p class="custom-paragraph custom-paragraph-xl text-black text-center">
          <b>SNBP, SNBT, & Kedinasan</b>
        </p>
      </div>
      <div class="paragraph-4 mb-4">
        <center>
          <b-img class="" :src="panah" alt="" width="auto" height="25px" />
        </center>
      </div>
      <div class="paragraph-4 mb-2 px-3 text-black">
        <p class="custom-paragraph-sm text-center" style="line-height: 2.2rem">
          Ukur, Asah dan Latih Kemampuanmu bersama EINSTEIN LEARNING PLUS dengan
          PROGRAM LENGKAP, TUTOR HEBAT, FASILITAS DAHSYAT dan BIAYA TERJANGKAU
          yang sudah TERBUKTI MELULUSKAN RIBUAN SISWA Setiap Tahunnya
        </p>
      </div>
    </div>
    <div class="my-4 d-none d-md-block">
      <center>
        <span style="font-size: 3rem; color: black" class="text-center">
          <b>Pusat Bimbingan Belajar SD, SMP, SMA / SMK</b>
        </span>
        <br />
        <span
          style="font-size: 3rem; color: black; margin-bottom: 10px"
          class="text-center d-block"
        >
          <b>
            <span class="text-primary">EINSTEIN LEARNING PLUS</span>
          </b>
        </span>
        <span
          style="font-size: 2rem; color: black; margin-bottom: 5px"
          class="text-center d-block"
        >
          <b>Persiapan UH,PTS,PAS,US,SNBP,SNBT dan Kedinasan</b>
        </span>
        <br />
        <b-img class="" :src="panah" alt="" width="auto" height="28px" />
        <p
          class="mt-3 text-dark container"
          style="width: 80%; line-height: 2.2rem"
        >
          Ukur, Asah dan Latih Kemampuanmu bersama EINSTEIN LEARNING PLUS dengan
          PROGRAM LENGKAP, TUTOR HEBAT, FASILITAS DAHSYAT dan BIAYA TERJANGKAU
          yang sudah TERBUKTI MELULUSKAN RIBUAN SISWA Setiap Tahunnya
        </p>
      </center>
    </div>
  </section>
</template>

<script>
import {
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BNav,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCard,
    BNav,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      img: require("@/assets/images/elp/ELP.png"),
      panah: require("@/assets/images/elp/panah melengkung.png"),
    };
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

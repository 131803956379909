var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('br'), _vm._m(0), _c('center', [_c('h4', {
    staticClass: "mb-2",
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "align": "center",
      "text-color": "primary"
    }
  }, [_vm._v(" Ulasan dari Para Alumni yang Sukses Setelah Mengikuti Try Out di ELP ")])]), _c('br'), _c('center', [_c('div', {
    staticStyle: {
      "width": "85%"
    }
  }, [_c('swiper', {
    staticClass: "swiper-responsive-breakpoints",
    attrs: {
      "options": _vm.swiperOptions,
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
    }
  }, [_vm._l(_vm.testimonies, function (testimony, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('b-card', [_c('b-card-body', [_c('center', [_c('div', [_c('img', {
      directives: [{
        name: "lazy",
        rawName: "v-lazy",
        value: "/testimoni/".concat(index, ".").concat(testimony.image_ext),
        expression: "`/testimoni/${index}.${testimony.image_ext}`"
      }],
      staticClass: "mb-1 rounded-circle",
      staticStyle: {
        "width": "100px",
        "height": "100px"
      },
      attrs: {
        "alt": "Photo"
      }
    })]), _c('h6', {
      staticClass: "font-weight-bolder mb-1 text-center"
    }, [_vm._v(" " + _vm._s(testimony.name) + " ")]), _c('span', {}, [_vm._v(_vm._s(testimony.school_origin))])]), _c('section', {
      staticClass: "text-center mt-2"
    }, [_c('strong', [_vm._v(_vm._s(testimony.achievement))])]), _c('section', {
      staticClass: "d-flex justify-content-center"
    }, [_c('b-form-rating', {
      attrs: {
        "value": "5",
        "no-border": "",
        "readonly": "",
        "variant": "warning",
        "inline": "",
        "size": "sm"
      }
    })], 1), _c('b-card-text', {
      staticClass: "text-center"
    }, [_vm._v(" \"" + _vm._s(testimony.comment) + "\" ")])], 1)], 1)], 1);
  }), _c('div', {
    staticClass: "swiper-pagination mt-4",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  })], 2)], 1)])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "mt-3 mb-2 text-primary",
    attrs: {
      "align": "center",
      "text-color": "primary"
    }
  }, [_c('b', [_vm._v("Kata Mereka")])]);
}]

export { render, staticRenderFns }
<template>
  <section>
    <div class="my-4 d-block d-md-none">
      <div class="paragraph-1 mb-2">
        <p
          class="custom-paragraph custom-paragraph-xl text-black text-center mb-1"
        >
          <b>Alasan Kenapa Kami</b>
        </p>
        <p class="custom-paragraph custom-paragraph-xl text-black text-center">
          <b>Harus Ikut Tryout di ELP</b>
        </p>
      </div>

      <div class="paragraph-2 mb-2">
        <p
          class="custom-paragraph custom-paragraph-lg text-primary text-center mb-1"
        >
          <b>Program Unggulan</b>
        </p>
        <p class="custom-paragraph-xl text-center mb-1">
          <strong class="text-primary">EINSTEIN LEARNING PLUS</strong>
        </p>
        <p
          class="custom-paragraph-md text-center text-dark mb-3"
          style="line-height: 2rem"
        >
          Fasilitas Dahsyat Program Einstein Learning Plus Untuk Mencapai Target
          Akademikmu
        </p>
      </div>
    </div>
    <div
      class="d-none d-md-block"
      style="margin-top: 100px; margin-bottom: 100px"
    >
      <h3 class="text-dark mb-2" align="center">
        <b>Alasan Kenapa Kamu Harus Ikut Tryout di ELP</b>
      </h3>
      <h3 class="text-primary" align="center">
        <b>Program Unggulan EINSTEIN LEARNING PLUS</b>
      </h3>
      <div
        class="mb-2 text-dark"
        align="center"
        style="
          /* body/18 Reguler */

          font-family: 'General Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 27px;
          /* identical to box height, or 150% */

          display: flex;
          align-items: center;

          /* text/secondary */

          color: #5a5a5a;

          /* Inside auto layout */

          flex: none;
          order: 1;
          flex-grow: 0;
        "
      >
        Fasilitas Dahsyat Program Einstein Learning Plus Untuk Mencapai Target
        Akademikmu
      </div>
    </div>
    <b-row>
      <b-col sm="12" md="6" lg="3" v-for="(item, i) in advantages" :key="i">
        <b-card class="mb-2" :style="item.styleCard" text-variant="#fffff">
          <b-card-text>
            <feather-icon
              class="mb-1 float-left"
              :style="item.styleIcon"
              size="24"
              :icon="item.icon"
            />
            <br />
            <h5 class="text-left my-2" style="color: black">
              <div v-html="item.nameHtml"></div>
            </h5>
            <section class="text-left">
              <b-button
                size="sm"
                variant="outline-secondary"
                @click.prevent="viewAdvantage(item)"
              >
                Selengkapnya
              </b-button>
            </section>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      id="modal-view-advantage"
      no-close-on-backdrop
      no-close-on-esc
      size="lg"
    >
      <section v-if="currentContent == 'program_kedokteran'">
        <h3 class="text-primary mb-1">
          <strong>Fasilitas dan Keunggulan Program Kedokteran</strong>
        </h3>
        <ol class="mb-2">
          <li>All in program 1 tahun ajaran</li>
          <li>Konsultasi strategi pilihan jurusan</li>
          <li>Pengajar profesional dan kompeten di bidangnya</li>
          <li>Ruang belajar ber-AC dan free hotspot area</li>
          <li>Tes IQ & minat bakat</li>
          <li>BTS (diskusi PR / Tugas)</li>
          <li>Modul & materi lengkap sesuai yang diuji</li>
          <li>1 kelas 8 orang</li>
          <li>Semester 1 (6x pertemuan /minggu)</li>
          <li>Semester 2 (8x pertemuan /minggu)</li>
        </ol>
        <h3 class="text-primary mb-1">Materi Pelajaran</h3>
        <b-row class="align-items-start">
          <b-col sm="12" md="6">
            <strong class="d-block"
              >Materi Sekolah (Persiapan UH, PTS & PAS)</strong
            >
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CheckSquareIcon"
                size="24"
                class="mr-1"
              ></feather-icon>
              <p>
                Matematik, Kimia, Fisika, Biologi, Bahasa Indonesia, Bahasa
                Inggris
              </p>
            </div>
          </b-col>
          <b-col sm="12" md="6">
            <strong class="d-block">Materi UTBK (Persiapan SNBT)</strong>
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CheckSquareIcon"
                size="24"
                class="mr-1"
              ></feather-icon>
              <p>TPS (PU, PBM, PPU & PK)</p>
            </div>
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CheckSquareIcon"
                size="24"
                class="mr-1"
              ></feather-icon>
              <p>Literasi B. Indonesia & B.Inggris</p>
            </div>
            <div class="d-flex align-items-center">
              <feather-icon
                icon="CheckSquareIcon"
                size="24"
                class="mr-1"
              ></feather-icon>
              <p>Penalaran Matematika</p>
            </div>
          </b-col>
        </b-row>
      </section>
      <section v-if="currentContent == 'program_kedinasan'">
        <h3 class="text-primary mb-1">
          <strong>Fasilitas Program Kedinasan</strong>
        </h3>
        <h5 class="text-primary mb-1">Fasilitas Kedinasan:</h5>
        <ol class="mb-2">
          <li>Seluruh Materi SKD</li>
          <ul>
            <li>TWK (Tes Wawasan Kebangsaan)</li>
            <li>TIU (Tes Intelegensi Umum)</li>
            <li>TKP (Tes Karakteristik Pribadi)</li>
          </ul>
          <li>Latihan Samapta / Jasmani / Fisik</li>
          <li>Mentor Berpengalaman</li>
          <li>Tempat Nyaman, Ruangan ber-AC</li>
        </ol>
        <h5 class="text-primary mb-1">Fasilitas Polri:</h5>
        <ol class="mb-2">
          <li>Seluruh Materi TPA</li>
          <ul>
            <li>PU (Tes Pengetahuan Umum)</li>
            <li>Tes Pengetahuan Polri</li>
            <li>Matematika, Bhs. Inggris, Bhs. Indonesia, Kewarganegaraan</li>
            <li>TKP (Tes Karakteristik Pribadi)</li>
          </ul>
          <li>Latihan Samapta / Jasmani / Fisik</li>
          <li>Mentor Berpengalaman</li>
          <li>Tempat Nyaman, Ruangan ber-AC</li>
        </ol>
      </section>
      <section v-if="currentContent == 'fasilitas_program'">
        <h3 class="text-primary mb-1">
          <strong>Fasilitas Program Super Intensive UTBK dan Karantina</strong>
        </h3>
        <b-row>
          <b-col sm="12" md="6">
            <h4 class="text-dark">
              <strong>Program Super Intensive UTBK</strong>
            </h4>
            <ul type="circle">
              <li>6 Pertemuan /minggu</li>
              <li>Belajar sampai H-1 tes UTBK 2024</li>
              <li>Modul Lengkap Sesuai Materi Uji SNBT 2024</li>
              <li>Seminar dan Konsultasi Jurusan PTN</li>
              <li>TRYOUT Progresif Mirip SNBT</li>
              <li>1 Kelas Maksimal 15 Orang</li>
            </ul>
          </b-col>
          <b-col sm="12" md="6">
            <h4 class="text-dark"><strong>Program Karantina</strong></h4>
            <ul type="circle">
              <li>
                18 Pertemuan /minggu (total pertemuan: 72 KBM, 36x Privat)
              </li>
              <li>
                3 Pertemuan /hari (2x KBBM Grup, 1x Privat 2 orang, 1 Tutor)
              </li>
              <li>1 Kelas Maksimal 10 orang</li>
              <li>Lama Belajar (awal April sampai dengan Pertengahan Mei)</li>
              <li>Tryout Setiap Hari</li>
              <li>TRYOUT Progresif mirip LTMPT (BP3)</li>
              <li>Modul Lengkap</li>
              <li>Konsultasi dan Pendampingan</li>
              <li>Seminar Morivasi</li>
            </ul>
          </b-col>
        </b-row>
      </section>
      <section v-if="currentContent == 'program_smp'">
        <h3 class="text-primary mb-1">
          <strong>Program Belajar Kelas 7,8, dan 9 SMP</strong>
        </h3>
        <p class="text-dark mb-2">
          Dirancang untuk meningkatkan nilai RAPOR dengan cara membantu siswa
          belajar sesuai bahan ajar harian di sekolah. Program dilengkapi dengan
          persiapan ulangan harian, PTS, PAS dan AKM agar siswa bisa menghadapi
          ujian.
        </p>
        <h4 class="text-primary mb-1">
          <strong>Keunggulan EINSTEIN</strong>
        </h4>
        <ol>
          <li>
            Pengajar kompeten dan berpengalaman (pengajar lulusan PTN terbaik
            dan mengajar dilengkapi dengan trik cepat (the best) dalam
            mengerjakan soal)
          </li>
          <li>Ruang belajar nyaman, ber-AC dan berkarpet</li>
          <li>Modul belajar lengkap</li>
          <li>
            Konsultasi jurusan (agar siswa tau strategi yang tepat dalam
            pemilihan jurusan sesuai minat dan bakat)
          </li>
          <li>Seminar Motivasi dan Info PTN</li>
          <li>
            Tes Minat Bakat (untuk mengetahui minat seseorang terhadap suatu
            pekerjaan, sebagai bahan pertimbangan ketika memilih jurusan
            perkualiahan)
          </li>
          <li>Tryout CAT / ONLINE</li>
          <li>
            TST / BTS (Best Time Service) : Jadwal tambahan sesuai request siswa
          </li>
          <li>GRATIS belajar speaking</li>
          <li>Pertemuan seminggu 4 kali / 90 menit per-pertemuan</li>
        </ol>
      </section>

      <template #modal-footer>
        <b-button
          variant="secondary"
          @click.prevent="$bvModal.hide('modal-view-advantage')"
          >Tutup</b-button
        >
      </template>
    </b-modal>
  </section>
</template>

<script>
import { BLink } from "bootstrap-vue";
import {
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardText,
  BCardFooter,
  BCardBody,
  BCardTitle,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BTable,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BLink,
    BTable,
    BFormGroup,
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardFooter,
    BCardBody,
    BCardTitle,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      currentContent: "program_kedokteran",
      advantages: [
        {
          name: "program_kedokteran",
          styleCard: {
            background: "rgba(5, 255, 5, 0.19)",
            height: "190px",
            overflow: "hidden",
          },
          styleIcon: {
            color: "rgb(31, 148, 31)",
          },
          nameHtml: `<b class="d-block">Fasilitas & Keunggulan</b>
                <b class="d-block">Program Kedokteran</b>`,
          icon: "ShoppingBagIcon",
        },
        {
          name: "program_kedinasan",
          styleCard: {
            background: "rgba(12, 93, 179, 0.139)",
            height: "190px",
            overflow: "hidden",
          },
          styleIcon: {
            color: "rgb(10, 105, 189)",
          },
          nameHtml: `<b class="d-block">Fasilitas Program Kedinasan</b>`,
          icon: "CalendarIcon",
        },
        {
          name: "fasilitas_program",
          styleCard: {
            background: "rgba(255, 208, 0, 0.294)",
            height: "190px",
            overflow: "hidden",
          },
          styleIcon: {
            color: "rgb(233, 193, 13)",
          },
          nameHtml: `<b class="d-block">Fasilitas Program</b>
                <b class="d-block">Super Intensive UTBK & Karantina</b>`,
          icon: "GlobeIcon",
        },
        {
          name: "program_smp",
          styleCard: {
            background: "rgba(219, 9, 219, 0.178)",
            height: "190px",
            overflow: "hidden",
          },
          styleIcon: {
            color: "purple",
          },
          nameHtml: `<b class="d-block">Program Belajar Kelas 7,8, dan 9 SMP</b>`,
          icon: "BarChartIcon",
        },
      ],
      fields: ["first_name", "last_name", "age"],
      items: [],
      img: require("@/assets/images/logo/logo.jpg"),
    };
  },
  methods: {
    viewAdvantage(item) {
      const { name } = item;
      this.currentContent = name;
      this.$bvModal.show("modal-view-advantage");
    },
  },
};
</script>

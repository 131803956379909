var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return Object.keys(_vm.profileData).length ? _c('div', {
    attrs: {
      "id": "user-profile"
    }
  }, [_c('nav', {
    staticClass: "header-navbar align-items-center navbar-brand-center navbar-fixed"
  }, [_vm._t("navbar", [_c('profile-header', {
    attrs: {
      "header-data": _vm.profileData.header
    }
  })])], 2), _c('header-satu'), _c('profile-slider'), _c('section', {
    attrs: {
      "id": "profile-info"
    }
  }, [_c('div', {
    staticClass: "container",
    staticStyle: {
      "width": "87%"
    }
  }, [_c('mengapa-einstein'), _c('paket-paket'), _c('br')], 1), _c('div', {
    staticStyle: {
      "background": "#f2ebe6"
    }
  }, [_c('kata-mereka', {
    staticClass: "mt-2 mb-4",
    staticStyle: {
      "background": "#f2ebe6"
    }
  }), _c('br'), _c('br')], 1), _c('footer-home')], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }
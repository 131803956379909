var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    staticClass: "mt-2 mb-4 d-block d-md-none"
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _c('div', {
    staticClass: "paragraph-4 mb-4"
  }, [_c('center', [_c('b-img', {
    attrs: {
      "src": _vm.panah,
      "alt": "",
      "width": "auto",
      "height": "25px"
    }
  })], 1)], 1), _vm._m(3)]), _c('div', {
    staticClass: "my-4 d-none d-md-block"
  }, [_c('center', [_c('span', {
    staticClass: "text-center",
    staticStyle: {
      "font-size": "3rem",
      "color": "black"
    }
  }, [_c('b', [_vm._v("Pusat Bimbingan Belajar SD, SMP, SMA / SMK")])]), _c('br'), _c('span', {
    staticClass: "text-center d-block",
    staticStyle: {
      "font-size": "3rem",
      "color": "black",
      "margin-bottom": "10px"
    }
  }, [_c('b', [_c('span', {
    staticClass: "text-primary"
  }, [_vm._v("EINSTEIN LEARNING PLUS")])])]), _c('span', {
    staticClass: "text-center d-block",
    staticStyle: {
      "font-size": "2rem",
      "color": "black",
      "margin-bottom": "5px"
    }
  }, [_c('b', [_vm._v("Persiapan UH,PTS,PAS,US,SNBP,SNBT dan Kedinasan")])]), _c('br'), _c('b-img', {
    attrs: {
      "src": _vm.panah,
      "alt": "",
      "width": "auto",
      "height": "28px"
    }
  }), _c('p', {
    staticClass: "mt-3 text-dark container",
    staticStyle: {
      "width": "80%",
      "line-height": "2.2rem"
    }
  }, [_vm._v(" Ukur, Asah dan Latih Kemampuanmu bersama EINSTEIN LEARNING PLUS dengan PROGRAM LENGKAP, TUTOR HEBAT, FASILITAS DAHSYAT dan BIAYA TERJANGKAU yang sudah TERBUKTI MELULUSKAN RIBUAN SISWA Setiap Tahunnya ")])], 1)], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "paragraph-1 mb-2"
  }, [_c('p', {
    staticClass: "custom-paragraph custom-paragraph-xl text-black text-center mb-1"
  }, [_c('b', [_vm._v("Pusat Bimbingan Belajar")])]), _c('p', {
    staticClass: "custom-paragraph custom-paragraph-xl text-black text-center"
  }, [_c('b', [_vm._v("SD, SMP, SMA / SMK")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "paragraph-2 mb-2"
  }, [_c('p', {
    staticClass: "custom-paragraph-xxl text-center"
  }, [_c('strong', {
    staticClass: "text-primary"
  }, [_vm._v("EINSTEIN LEARNING PLUS")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "paragraph-3",
    staticStyle: {
      "margin-bottom": "5px"
    }
  }, [_c('p', {
    staticClass: "custom-paragraph custom-paragraph-xl text-black text-center mb-1"
  }, [_c('b', [_vm._v("Persiapan UH, PTS, PAS, US")])]), _c('p', {
    staticClass: "custom-paragraph custom-paragraph-xl text-black text-center"
  }, [_c('b', [_vm._v("SNBP, SNBT, & Kedinasan")])])]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "paragraph-4 mb-2 px-3 text-black"
  }, [_c('p', {
    staticClass: "custom-paragraph-sm text-center",
    staticStyle: {
      "line-height": "2.2rem"
    }
  }, [_vm._v(" Ukur, Asah dan Latih Kemampuanmu bersama EINSTEIN LEARNING PLUS dengan PROGRAM LENGKAP, TUTOR HEBAT, FASILITAS DAHSYAT dan BIAYA TERJANGKAU yang sudah TERBUKTI MELULUSKAN RIBUAN SISWA Setiap Tahunnya ")])]);
}]

export { render, staticRenderFns }
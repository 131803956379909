var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-col', [_c('h3', {
    staticClass: "mt-4 mb-1 text-primary",
    attrs: {
      "align": "center",
      "text-color": "primary"
    }
  }, [_c('b', [_vm._v(" Pilih Paket Bimbel & Try Out Sekarang ")])]), _c('h4', {
    staticClass: "mb-2 text-center"
  }, [_vm._v(" Berbagai macam pilihan paket sesuai dengan kebutuhanmu ")]), _c('b-row', _vm._l(_vm.data, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "md": "6",
        "xl": "3"
      }
    }, [_c('div', {
      staticClass: "card",
      on: {
        "click": function click($event) {
          return _vm.detail(item);
        }
      }
    }, [_c('img', {
      directives: [{
        name: "lazy",
        rawName: "v-lazy",
        value: item.thumbnail != null ? _vm.apiFile + item.thumbnail : require('@/assets/images/elp/no-image.jpg'),
        expression: "item.thumbnail != null\n                ? apiFile + item.thumbnail\n                : require('@/assets/images/elp/no-image.jpg')"
      }],
      staticClass: "card-img-top mb-2 text-left"
    }), _c('div', {
      staticClass: "card-body"
    }, [_c('section', {
      staticClass: "text-left"
    }, [_c('small', {
      staticClass: "d-block text-secondary mb-1"
    }, [_vm._v(_vm._s(item.sum_subtest_content) + " subtes")]), _c('h5', [_c('strong', {
      staticClass: "text-dark"
    }, [_vm._v(_vm._s(item.name))])])]), _c('div', {
      staticClass: "card__arrow",
      style: 'background:' + _vm.getColor(item.category.color)
    })])])]);
  }), 1), _c('center', [_c('b-button', {
    staticClass: "mt-1 mb-2",
    attrs: {
      "variant": "outline-primary",
      "to": {
        name: 'paket-selengkapnya'
      }
    }
  }, [_vm._v(" Selengkapnya ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }